import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet';
import CoinLeaderboard from './CoinLeaderboard';
import LVLLeaderboard from './LVLLeaderboard';
import ClansLeaderboard from './ClansLeaderboard';
import './css/leaderboard.css';

const Leaderboards = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const location = useLocation();
    const path = location.pathname;
    const [countdown, setCountdown] = useState('...');
    const [isLeaderboardVisible, setIsLeaderboardVisible] = useState(true);


    // Declare setSelectedOption using the useState hook
    const [selectedOption, setSelectedOption] = useState(
        path.includes('/leaderboard/lvl') ? 'lvl' : (path.includes('/leaderboard/coins') ? 'coins' : 'clans')
    );

    const [seasonData, setSeasonData] = useState({});

    useEffect(() => {
        // Fetch season data from your API
        fetch('https://be-bot.limro.top/api/botinfo')
            .then((response) => response.json())
            .then((data) => {
                if (Array.isArray(data) && data.length > 0) {
                    // Assuming 'season' and 'season_end_date' are properties of the object at index 0
                    const dataa = data[0];
                    setSeasonData({
                        season: dataa.season,
                        season_end_date: dataa.season_end_date,
                    });
                } else {
                    console.error('Response is not an array or is empty');
                }
            })
            .catch((error) => {
                console.error('Error fetching season data:', error);
            });
    }, []);

    useEffect(() => {
        const calculateCountdown = () => {
            const now = new Date();
            const endDate = new Date(seasonData.season_end_date);
            const timeLeft = endDate - now;

            const days = Math.floor(timeLeft / (1000 * 60 * 60 * 24));
            const hours = Math.floor((timeLeft % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
            const minutes = Math.floor((timeLeft % (1000 * 60 * 60)) / (1000 * 60));
            const seconds = Math.floor((timeLeft % (1000 * 60)) / 1000);

            return `${days}d ${hours}h ${minutes}m ${seconds}s`;
        };

        const updateCountdown = () => {
            setCountdown(calculateCountdown());
        };

        const countdownInterval = setInterval(updateCountdown, 1000);

        return () => {
            clearInterval(countdownInterval);
        };
    }, [seasonData]);

    {/*const handleOptionChange = (event) => {
        const newSelectedOption = event.target.value;
        setSelectedOption(newSelectedOption);
        navigate(`/leaderboard/${newSelectedOption}`);
    };*/}

    const handleOptionChange = (event) => {
        const newSelectedOption = event.target.value;
        setIsLeaderboardVisible(false); // Hide leaderboard with fade-out animation
    
        // Wait for the fade-out animation to complete before changing data
        setTimeout(() => {
            setSelectedOption(newSelectedOption);
            navigate(`/leaderboard/${newSelectedOption}`);
    
            // After changing the selected option and navigating, show the leaderboard again
            setTimeout(() => {
                setIsLeaderboardVisible(true); // Show leaderboard after a short delay
            }, 300); // Adjust the delay time as needed
        }, 300); // Duration of the fade-out animation
    };

    return (
        <div>
            <div className='main_resizer'>
                <div className="heading33">
                    <Helmet>
                        <title>{t('home_leaderboards')} | LimroBot</title>
                    </Helmet>
                    <div className='container1'>
                        <div className='containerr'>
                            <h1 className="display-5 title2">{t('ld_header')}</h1>
                            <p>{t('ld_currse')} {seasonData.season}</p>
                            <p>{t('ld_seasonend')} {seasonData.season_end_date}</p>
                            <p>{t('ld_countdown')} {countdown}</p>
                            <div className="select-container">
                                <select
                                    className="select-dropdown"
                                    id="leaderboardSelect"
                                    value={selectedOption}
                                    onChange={handleOptionChange}
                                >
                                    <option value="coins">{t('ld_choice1')}</option>
                                    <option value="lvl">{t('ld_choice2')}</option>
                                    <option value="clans">{t('ld_choice3')}</option>
                                </select>
                            </div>
                        </div>
                    </div>
                    {/*<div className='coin-leaderboard'>
                        {selectedOption === 'coins' && <CoinLeaderboard />}
                        {selectedOption === 'lvl' && <LVLLeaderboard />}
                        {selectedOption === 'clans' && <ClansLeaderboard />}
                    </div>*/}
                    <div className={`coin-leaderboard ${isLeaderboardVisible ? '' : 'hidden'}`}>
                        {selectedOption === 'coins' && <CoinLeaderboard />}
                        {selectedOption === 'lvl' && <LVLLeaderboard />}
                        {selectedOption === 'clans' && <ClansLeaderboard />}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Leaderboards;