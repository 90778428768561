import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import Cookies from 'js-cookie';
import './css/leaderboard.css'; // Import the CSS file

const GamblingProfile = () => {
    const { t } = useTranslation();
    const [gamblingData, setGamblingData] = useState({ rank: null, coins: null });
    const [clanData, setClanData] = useState({ name: null, clan_lvl: null });

    useEffect(() => {
        const fetchData = async () => {
            try {
                const sessionCookie = Cookies.get('session');
                const response = await fetch(`https://be-bot.limro.top/request/profile/casinoprofile`, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        'Cookie': sessionCookie,
                        Authorization: `Bearer ${sessionCookie}`,
                    },
                });
                if (response.ok) {
                    const data = await response.json();

                    setGamblingData(data.gamblingData || { rank: null, coins: null });
                    setClanData(data.clanInfo || { name: null, clan_lvl: null });
                } else {
                    console.error('Failed to fetch data');
                }
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        fetchData();
    }, []);

    return (
        <div>
            <h1 className='display-5 title'>{t('profile_gamep')}</h1>
            <div className="dropdown-divider"></div>
            <div className='other-container'>
                <p>{t('profile_rank')}: {gamblingData.rank || 'null'}</p>
                <p>{t('profile_coins')}: {gamblingData.cash ?? 'null'}</p>
                <div className="dropdown-divider"></div>
                <p>{t('profile_cname')}: {clanData.name ?? 'null'}</p>
                <p>{t('profile_clvl')}: {clanData.clan_lvl ?? 'null'}</p>
                <div className="dropdown-divider"></div>
            </div>
        </div>
    );
};

export default GamblingProfile;