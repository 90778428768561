import React, { useState, useEffect } from 'react';
import './css/bootstrap.min.css';
import './css/main.css';
import './css/now-ui-kit.css';
import './App.css';
import './css/subscriptions.css';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDiscord } from '@fortawesome/free-brands-svg-icons';
import { Helmet } from 'react-helmet';
import Cookies from 'js-cookie';
import axios from 'axios';
import { loadStripe } from '@stripe/stripe-js';

const Features = ({ loggedIn, setLoggedIn, user, setUser, guilds, setGuilds, sessionValid, setSessionValid, handleLogin }) => {
    const { t } = useTranslation();
    const [stripe, setStripe] = useState(null);

    useEffect(() => {
        const fetchStripe = async () => {
            //for test
            const stripeObj = await loadStripe('pk_test_51OkkhkI2iyV6Ui7BHX1aXPxlm9sU4AOfhVOOWUDJURahpfFeLS6Egqffeaq4f0kMxXs1oQKsC4MVf4doquHFvl6900o50HW56B');
            //for prod
            //const stripeObj = await loadStripe('pk_live_51OkkhkI2iyV6Ui7BDV4AjGoNZflEk4aBIHnMsOuqHGsiosTgIelBVOgiXB6HcsrSNXM8m3FXVd6knTkqdBXyptGc00hEgXtsax')
            setStripe(stripeObj);
        };

        fetchStripe();
    }, []);

    async function clearSession() {
        const sessionCookie = Cookies.get('session');
        await axios.get('https://be-bot.limro.top/auth/logout', {
            headers: {
                Authorization: `Bearer ${sessionCookie}`,
            },
        });
        Cookies.remove('session', { domain: '.limro.top' });
        setLoggedIn(false);
        setSessionValid(false);
    }

    useEffect(() => {
        const fetchData = async () => {
            const sessionCookie = Cookies.get('session');

            if (!sessionCookie) {
                console.error('Session not found!');
                // Redirect back to the login page
                clearSession();
                window.location.href = '/';
                return;
            }

            // Check if the session is valid
            const response = await axios.get('https://be-bot.limro.top/api/protected/check', {
                headers: {
                    Authorization: `Bearer ${sessionCookie}`,
                },
            });

            if (response.status === 200) {
                // Session is valid
                setLoggedIn(true);
                setSessionValid(true);
            } else {
                // Session is not valid
                console.error('Invalid session');
                clearSession();
                window.location.href = '/';
            }
        };

        if (loggedIn) {
            fetchData();
        }
    }, [loggedIn, setLoggedIn, setSessionValid]);

    const initiatePayment = async (tier, stripe) => {
        const userId = user.id;
        const checkUrl = 'https://be-bot.limro.top/payment/check';
        try {
            // Check if payment can be initiated
            const checkResponse = await fetch(checkUrl, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ userId })
            });
    
            if (checkResponse.status === 201) {
                // Prompt user to continue or cancel
                const confirmation = window.confirm(t('sub_conf'));
                if (confirmation) {
                    const url = `https://be-bot.limro.top/payment/${tier}`;
                    const paymentResponse = await fetch(url, {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json'
                        },
                        body: JSON.stringify({ userId })
                    });
        
                    if (paymentResponse.ok) {
                        const data = await paymentResponse.json();
                        const { sessionId } = data;
                        return stripe.redirectToCheckout({ sessionId });
                    } else {
                        console.error(`Error initiating ${tier} payment:`, paymentResponse.statusText);
                    }                       
                } else {
                // User clicked cancel
                    console.log('Payment canceled by user.');
                }
            } else if (checkResponse.status == 200) {
                const confirmation = window.confirm(t('sub_conf2'));
                if (confirmation) {
                    const url = `https://be-bot.limro.top/payment/${tier}`;
                    const paymentResponse = await fetch(url, {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json'
                        },
                        body: JSON.stringify({ userId })
                    });
        
                    if (paymentResponse.ok) {
                        const data = await paymentResponse.json();
                        const { sessionId } = data;
                        return stripe.redirectToCheckout({ sessionId });
                    }
                } else {
                    // User clicked cancel
                    console.log('Payment canceled by user.');
                }

            } else {
                // Show dialog window if payment check fails
                window.alert(t('sub_error'));
            }
        } catch (error) {
            console.error(`Error initiating ${tier} payment:`, error);
        }
    };
    
    const firstTier = () => {
        initiatePayment('firsttier', stripe);
    };
    
    const secondTier = () => {
        initiatePayment('secondtier', stripe);
    };
    
    const thirdTier = () => {
        initiatePayment('thirdtier', stripe);
    };

    return (
        <div>
            <Helmet>
                <title>{t('nav_subscription')} | LimroBot</title>
            </Helmet>

            <div className="container_sub">
                <h1 className="heading3 title">{t('nav_subscription')}</h1>
                <p>{t('sub_sub')}</p>
                {loggedIn ? ( // Check if the user is logged in
                        <div className="roww">
                            <div className="col-md-4">
                                <div className="container_tier_bronze text-center">
                                    <h3 className="title">LimroBot Bronze</h3>
                                    <div className="dropdown-divider"></div>
                                    <h5>{t('bronze_inc')}</h5>
                                    <div className="dropdown-divider"></div>
                                    <ul>
                                        <li>- {t('bronze_1')}</li>
                                        {/* <li>- Social media integrations</li> */}
                                        <li>- {t('bronze_2')}</li>
                                        <li>- {t('bronze_3')}</li>
                                        <li>- {t('bronze_4')}</li>
                                        {/* <li>- Custom bot avatar, name on server</li> */}
                                        <li>- {t('bronze_5')}</li>
                                        <li>- {t('bronze_6')}</li>
                                    </ul>
                                    <div className="dropdown-divider"></div>
                                    <div className="price">$1.99</div>
                                    <div className='btncontainer'>
                                        <btn className="btn btn-primary btn-lg" onClick={firstTier} role="button">{t('subscr_buy')}</btn>
                                    </div>
                                </div>
                            </div>
                            {/* <div className="col-md-4">
                                <div className="container_tier_silver text-center">
                                    <h3 className="title">LimroBot Silver</h3>
                                    <p>{t('sub_best')}</p>
                                    <div className="dropdown-divider"></div>
                                    <h5>Includes: 1 silver tier for user and 1 silver tier for server</h5>
                                    <div className="dropdown-divider"></div>
                                    <ul>
                                        <li>- All mentioned in LimroBot Bronze</li>
                                        <li>- Silver premium for 1 server</li>
                                        <li>- Custom commands 5 additional = 10 in total</li>
                                        <li>- Additional server statistics</li>
                                        <li>- Advanced logging system</li>
                                        <li>- 4 custom playlists (max 20 songs in each)</li>
                                        <li>- Custom welcome messages</li>
                                    </ul>
                                    <div className="dropdown-divider"></div>
                                    <div className="price">$3.99</div>
                                    <div className='btncontainer'>
                                        <btn className="btn btn-primary btn-lg" onClick={secondTier} role="button">Subscribe</btn>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="container_tier_gold text-center">
                                    <h3 className="title">LimroBot Gold</h3>
                                    <div className="dropdown-divider"></div>
                                    <h5>Includes: 1 gold tier for user and 1 gold tier for server</h5>
                                    <div className="dropdown-divider"></div>
                                    <ul>
                                        <li>- All mentioned in LimroBot Silver</li>
                                        <li>- Gold premium for 1 server</li>
                                        <li>- 10 additional custom commands = 20 in total</li>
                                        <li>- Voice recognition function and TTS answers (in voice chat) </li>
                                        <li>- TTS for people who can't speak in mic from spcified text channel (in voice chat)</li>
                                        <li>- Server data backup and restore</li>
                                        <li>- 10 custom playlists (max 20 songs in each)</li>
                                        <li>- Advanced custom welcome messages</li>
                                    </ul>
                                    <div className="dropdown-divider"></div>
                                    <div className="price">$6.99</div>
                                    <div className='btncontainer'>
                                        <btn className="btn btn-primary btn-lg" onClick={thirdTier} role="button">Subscribe</btn>
                                    </div>
                                </div>
                            </div> */}
                            <div className='heading3'>
                                <h2 className='title'>{t('subsc_faq_title')}</h2>
                                <p>- {t('subsc_faq_1')}</p>
                                <p>- {t('subsc_faq_2')}</p>
                                <p>- {t('subsc_faq_3')}</p>
                                <p>- {t('subsc_faq_4')}</p>
                            </div>
                        </div>
                        
                ) : (
                    <div className='heading3'>
                        <p>{t('sub_error2')}</p>
                        <div className='btncontainer'>
                                <a className="btn btn-primary btn-lg" onClick={handleLogin} rel="noopener noreferrer" role="button" ><FontAwesomeIcon icon={faDiscord} />  Login with Discord</a>
                        </div>
                        <div className='heading3'>
                                <h2 className='title'>{t('subsc_faq_title')}</h2>
                                <p>- {t('subsc_faq_1')}</p>
                                <p>- {t('subsc_faq_2')}</p>
                                <p>- {t('subsc_faq_3')}</p>
                                <p>- {t('subsc_faq_4')}</p>
                            </div>
                    </div>
                )}
            </div>

            <div className="card">
                <div className='container'>
                    <div className="card-body gs-card">
                        <div className="title">{t('home_support_1')}</div>
                        <div className="subtitle">{t('home_support_2')}</div>
                        <br />
                        <div className='btncontainer'>
                            <a className="btn btn-primary btn-lg" href="https://discord.gg/55WjVBMDvH" target="_blank" rel="noopener noreferrer" role="button">{t('home_getsupport')}</a>
                        </div>
                        <br />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Features;