import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import Cookies from 'js-cookie';
import './css/leaderboard.css'; // Import the CSS file
import CountUp from "react-countup";

const LanguagesProfile = () => {
    const { t } = useTranslation();
    const [languagesData, setLanguagesData] = useState(null);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const sessionCookie = Cookies.get('session');
                const response = await fetch(`https://be-bot.limro.top/request/profile/languagesprofile`, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        'Cookie': sessionCookie,
                        Authorization: `Bearer ${sessionCookie}`,
                    },
                });
                if (response.ok) {
                    const data = await response.json();
                    setLanguagesData(data);
                } else {
                    console.error('Failed to fetch data');
                }
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        fetchData();
    }, []);

    const renderLanguageProgress = () => {
        if (languagesData === null) {
            return null; // Data not found, return null
        }

        const totalProgress = (
            (languagesData.hsk1 || 0) + 
            (languagesData.hsk2 || 0) + 
            (languagesData.hsk3 || 0) + 
            (languagesData.hsk4 || 0) + 
            (languagesData.hsk5 || 0) + 
            (languagesData.hsk6 || 0)
        ) / 6;
        
        const totalWordsLearned = (
            (languagesData.hsk1_learned || 0) + 
            (languagesData.hsk2_learned || 0) + 
            (languagesData.hsk3_learned || 0) + 
            (languagesData.hsk4_learned || 0) + 
            (languagesData.hsk5_learned || 0) + 
            (languagesData.hsk6_learned || 0)
        );

        return (
            <div className='other-container'>
                <div className="dropdown-divider"></div>
                <h2 className='title5'>{t('profile_chinese')}: </h2>
                <p>{t('profile_hsk1_prog')}: <CountUp duration={4} end={languagesData.hsk1 || 0}/>% | {t('profile_words_learn')}: <CountUp duration={4} end={languagesData.hsk1_learned || 0}/></p>
                <p>{t('profile_hsk2_prog')}: <CountUp duration={4} end={languagesData.hsk2 || 0}/>% | {t('profile_words_learn')}: <CountUp duration={4} end={languagesData.hsk2_learned || 0}/></p>
                <p>{t('profile_hsk3_prog')}: <CountUp duration={4} end={languagesData.hsk3 || 0}/>% | {t('profile_words_learn')}: <CountUp duration={4} end={languagesData.hsk3_learned || 0}/></p>
                <p>{t('profile_hsk4_prog')}: <CountUp duration={4} end={languagesData.hsk4 || 0}/>% | {t('profile_words_learn')}: <CountUp duration={4} end={languagesData.hsk4_learned || 0}/></p>
                <p>{t('profile_hsk5_prog')}: <CountUp duration={4} end={languagesData.hsk5 || 0}/>% | {t('profile_words_learn')}: <CountUp duration={4} end={languagesData.hsk5_learned || 0}/></p>
                <p>{t('profile_hsk6_prog')}: <CountUp duration={4} end={languagesData.hsk6 || 0}/>% | {t('profile_words_learn')}: <CountUp duration={4} end={languagesData.hsk6_learned || 0}/></p>
                <div className="dropdown-divider"></div>
                <p>{t('profile_total_prog')}: <CountUp duration={4} end={(totalProgress || 0).toFixed(2)}/>% | {t('profile_total_words')}: <CountUp duration={4} end={totalWordsLearned}/></p>
                <div className="dropdown-divider"></div>
            </div>
        );
    };

    return (
        <div>
            <h1 className='display-5 title'>{t('profile_langp')}</h1>
            {renderLanguageProgress()}
        </div>
    );
};

export default LanguagesProfile;