import React from 'react';
import './css/bootstrap.min.css';
import './css/main.css';
import './css/now-ui-kit.css';
import './App.css';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet';

const LoginError = () => {
    const { t } = useTranslation();

        return (
            <div>
            <Helmet>
                <title>{t('error')} 401 | LimroBot</title>
            </Helmet>
            <div className="heading">
                <div className="unicode-symbol">⚠</div>
                <h1 className="display-5 title2">{t('login_error_title')} 401!</h1>
                <p className="subtitle2">
        {t('login_error_subtitle')}
        <br />
        {t('login_error_subtitle_2')}
      </p>
                <br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br />
            </div>
            <div className="card">
                <div className='container'>
                    <div className="card-body gs-card">
                    <div className="title">{t('404_broken')}</div>
                    <div className="subtitle">{t('404_broken_sub')}</div>
                    <br />
                    <div className='btncontainer'>
                        <a className="btn btn-primary btn-lg" href="https://discord.gg/55WjVBMDvH" target="_blank" rel="noopener noreferrer" role="button">{t('home_getsupport')}</a>
                    </div>
                    <br />
                </div>
            </div>

            {/* Add any other JSX content here if needed {t('404_broken')} {t('404_broken_sub')} */}
            </div>
            </div>
        );
};

export default LoginError;