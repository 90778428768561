import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Cookies from 'js-cookie';
import CryptoJS from 'crypto-js';

const Callback = () => {
  const navigate = useNavigate();

  const extractTokenFromFragment = () => {
    const fragment = window.location.hash.substring(1);
    const params = new URLSearchParams(fragment);
    return params.get('access_token');
  };

  useEffect(() => {
    const accessToken = extractTokenFromFragment();

    if (accessToken) {
      const secureKey = require('uuid').v4();

      Cookies.set('sessionid', secureKey, {
        expires: 0.25,
        httpOnly: false,
        secure: true,
        sameSite: 'strict',
      });
      const encryptedToken = CryptoJS.AES.encrypt(accessToken, secureKey).toString();
      Cookies.set('session', encryptedToken, {
        expires: 0.25,
        httpOnly: false,
        secure: true,
        sameSite: 'strict',
      });
      //sessionStorage.setItem('session', accessToken);

      // Fetch user data from the Discord API
      fetch('https://discord.com/api/v10/users/@me', {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      })
        .then((response) => response.json())
        .then((data) => {
          // Save user data in sessionStorage
          sessionStorage.setItem('userData', JSON.stringify(data));

          fetch('https://discord.com/api/v10/users/@me/guilds', {
            headers: {
              Authorization: `Bearer ${accessToken}`,
            },
          })
            .then((response) => response.json())
            .then((guilds) => {
              // Filter and save admin guilds in sessionStorage
              const guildData = guilds.filter((guild) => guild.owner);
              sessionStorage.setItem('guildData', JSON.stringify(guildData));

              navigate('/');
            })
            .catch((error) => {
              console.error('Error fetching admin guilds:', error);
              navigate('/loginerror');
            });
        })
        .catch((error) => {
          console.error('Error fetching user data:', error);
          navigate('/loginerror');
        });
    } else {
      console.error('No access token found in the URL fragment.');
      navigate('/loginerror');
    }
  }, [navigate]);

  return (
    <div>
      <p>Please wait...</p>
    </div>
  );
};

export default Callback;