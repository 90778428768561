import React from 'react';
import './css/bootstrap.min.css';
import './css/main.css';
import './css/now-ui-kit.css';
import './App.css';
import song from './assets/song.PNG';
import additional from './assets/additional.PNG';
import leaderboard from './assets/leaderboard.PNG';
import casino from './assets/casino.PNG';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet';

const ThanksForAdding = () => {
    const { t } = useTranslation();

    return (
        <div>
            <Helmet>
                <title>Thanks! | LimroBot</title>
            </Helmet>
            <div className="heading3">
                <div className='container2'>
                <h1 className="display-5 title2">{t('thanks_1')}</h1>
                </div>
            </div>
            <div className="card">
                <div className='container'>
                    <div className="card-body gs-card">
                        <div className="title">{t('home_support_1')}</div>
                        <div className="subtitle">{t('home_support_2')}</div>
                        <br />
                        <div className='btncontainer'>
                            <a className="btn btn-primary btn-lg" href="https://discord.gg/55WjVBMDvH" target="_blank" rel="noopener noreferrer" role="button">{t('home_getsupport')}</a>
                        </div>
                        <br />
                    </div>
                </div>
            </div>
            <div className="card">
                <div className='container'>
                    <div className="card-body gs-card">
                        <div className="title">{t('thanks_2')}</div>
                        <div className="subtitle">{t('thanks_3')}</div>
                        <br />
                        <div className='btncontainer'>
                            <a className="btn btn-primary btn-lg" href="https://limro-studios.gitbook.io/limro-sama-docs/setting-up-limrobot/setting-up-suggestions-channel" target="_blank" rel="noopener noreferrer" role="button">Tutorials</a>
                        </div>
                        <br />
                    </div>
                </div>
            </div>
            <div className="card">
                <div className='container'>
                    <div className="card-body gs-card">
                        <div className="title">{t('thanks_4')}</div>
                        <div className="subtitle">{t('thanks_5')}</div>
                        <br />
                        <div className='btncontainer'>
                            <a className="btn btn-primary btn-lg" href="https://limro-studios.gitbook.io/limro-sama-docs/limrobot-commands/standart-commands" target="_blank" rel="noopener noreferrer" role="button">Commands</a>
                        </div>
                        <br />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ThanksForAdding;