// ServerDashboard.js
import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import './css/bootstrap.min.css';
import './css/main.css';
import './css/now-ui-kit.css';
import './App.css';
import './css/dashboard.css';
import Cookies from 'js-cookie';
import defaultGuildIconUrl from './assets/noserverpfp.png';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import axios from 'axios';

const ServerDashboard = ({ loggedIn, setLoggedIn, user, setUser, guilds, setGuilds, sessionValid, setSessionValid }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();

  const [backendResponse, setBackendResponse] = useState('');
  const { selectedGuild } = location.state || {};
  const [selectedOption, setSelectedOption] = useState('');
  const [inputValue, setInputValue] = useState({});
  const [changesMade, setChangesMade] = useState(false);
  const [changesSaved, setChangesSaved] = useState(false);
  const [selectedButton, setSelectedButton] = useState(null);
  const [memberCountToggle, setMemberCountToggle] = useState(false);
  const [channelData, setChannelData] = useState({});
  const [languages, setLanguages] = useState([]);
  const [selectedLanguage, setSelectedLanguage] = useState('');
  const [memberCountEnabled, setMemberCountEnabled] = useState(false);

  const ToggleSwitch = ({ checked, onChange }) => {
    return (
      <label className="toggle-switch">
        <input type="checkbox" checked={checked} onChange={onChange} />
        <span className="slider"></span>
      </label>
    );
  };

  const fetchData = async () => {
    try {
      const sessionCookie = Cookies.get('session');

      if (!sessionCookie) {
        console.error('Session not found!');
        // Redirect back to the login page
        clearSession();
        window.location.href = '/';
        return;
      }

      // Check if the session is valid
      const response = await axios.get('https://be-bot.limro.top/api/protected/check', {
        headers: {
          Authorization: `Bearer ${sessionCookie}`,
        },
      });

      if (response.status === 200) {
        // Session is valid
        setLoggedIn(true);
        setSessionValid(true);
      } else {
        // Session is not valid
        console.error('Invalid session');
        clearSession();
        window.location.href = '/';
      }
    } catch (error) {
      console.error('Error fetching protected data:', error.message);
      clearSession();
      window.location.href = '/';
    }
  };

  if (loggedIn) {
    fetchData();
  }

  async function clearSession() {
    const sessionCookie = Cookies.get('session');
    await axios.get('https://be-bot.limro.top/auth/logout', {
      headers: {
        Authorization: `Bearer ${sessionCookie}`,
      },
    });
    Cookies.remove('session', { domain: '.limro.top' });
    setLoggedIn(false);
    setSessionValid(false);
  }

  useEffect(() => {
    // Fetch channel data and languages when the component mounts
    Promise.all([
      fetch(`https://be-bot.limro.top/api/channels/${selectedGuild.id}`).then(response => response.json()),
      // Fetch the list of supported languages
      fetch('https://be-bot.limro.top/api/languages').then(response => response.json())
      //fetch member count on off
    ])
    .then(([channelsData, languagesData]) => {
      setChannelData(channelsData);
      setLanguages(languagesData);
  
      const defaultValues = {
        'Member Count': channelsData['nsfw']?.[0]?.channel_id || '',
        'Set NSFW Channel': channelsData['nsfw']?.[0]?.channel_id || '',
        'Set Suggestions Channel': channelsData['suggestions']?.[0]?.channel_id || '',
        'Set Event Channel': channelsData['events']?.[0]?.channel_id || '',
        'Set Log Channel': channelsData['logs']?.[0]?.channel_id || '',
        'Set Giveaway Channel': channelsData['giveaways']?.[0]?.channel_id || '',
        'Set Server Language': channelsData['language']?.[0]?.language || 'en-US', // Set default to en-US
        // Add other default values as needed
      };
  
      setInputValue(defaultValues);
      setSelectedLanguage(defaultValues['Set Server Language']);
    })
    .catch(error => {
      console.error('Error saving changes:', error.message);
      setBackendResponse(error.message);
      sessionStorage.removeItem('backendResponse');
      sessionStorage.setItem('backendResponse', error.message);
    });

    const storedBackendResponse = sessionStorage.getItem('backendResponse');
    if (storedBackendResponse) {
      setBackendResponse(storedBackendResponse);
    }
  }, []);


  const handleLanguageChange = (e) => {
    setSelectedLanguage(e.target.value);
    handleInputChange({ target: { value: e.target.value } }, 'Set Server Language');
  };

  const handleBackClick = () => {
    navigate('/dashboard');
  };

  const handleOptionSelect = (option) => {
    if (changesMade) {
      const userDecision = window.confirm(t('dash_save?'));
      if (userDecision) {
        setChangesMade(false);
        setChangesSaved(true);
      } else {
        //setSelectedOption(defaultValues[selectedOption]);
        setChangesMade(false);
        setChangesSaved(false);
        return;
      }
    }
    setSelectedOption(option);
  };

  const handleInputChange = (e, option) => {
    setInputValue((prevInputValue) => ({
      ...prevInputValue,
      [option]: e.target.value,
    }));
    setChangesMade(true);
  };

  const handleSaveClick = async () => {
    // Assuming your API endpoint is https://example.com/request/data/set
    const apiUrl = 'https://be-bot.limro.top/request/data/set';
  
    // Prepare the data to be sent in the request
    const requestData = {
      session: Cookies.get('session'), // Assuming you're using the 'js-cookie' library
      inputValue,
      selectedOption,
      selectedGuild
    };
  
    try {
      // Make a POST request to the API endpoint
      const response = await fetch(apiUrl, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(requestData),
      });
  
      if (!response.ok) {
        setBackendResponse('Failed to save changes');
        sessionStorage.removeItem('backendResponse');
        sessionStorage.setItem('backendResponse', 'Failed to save changes');
        throw new Error('Failed to save changes');
      }
      setBackendResponse('Changes saved successfully');
      sessionStorage.removeItem('backendResponse');
      sessionStorage.setItem('backendResponse', 'Changes saved successfully');
      const data = await response.json();
      console.log('Changes saved successfully:', data);
  
      // Update the state
      setChangesMade(false);
      setChangesSaved(true);
  
      // Reset the changes saved status after 2000 milliseconds (2 seconds)
      setTimeout(() => setChangesSaved(false), 2000);
      //await new Promise(resolve => setTimeout(resolve, 2000));
      window.location.href = `/dashboard/guild/${selectedGuild.id}`;
    } catch (error) {
      setBackendResponse(error.message);
      sessionStorage.removeItem('backendResponse');
      sessionStorage.setItem('backendResponse', error);
      console.error('Error saving changes:', error.message);
      // Handle the error as needed
    }
  };

  return (
    <div className="server-dashboard-container">
      <Helmet>
        <title>"{selectedGuild.name}" {t('settings')} | LimroBot</title>
      </Helmet>
      {/* Sidebar */}
      <div className="sidebar">
        <h2>
          <img
            src={`https://cdn.discordapp.com/icons/${selectedGuild.id}/${selectedGuild.icon}.png`}
            className="guild-icon"
            onError={(e) => {
              if (!selectedGuild.icon) {
                e.target.src = defaultGuildIconUrl;
              }
            }}
          />
          {selectedGuild.name} {t('dash_settings')}
        </h2>
        <ul>
          {/*<li>
            <button onClick={() => handleOptionSelect('Member Count')}>{t('dash_member_count')}</button>
          </li>*/}
          <li>
            <button onClick={() => handleOptionSelect('Set NSFW Channel')}>{t('dash_nsfw_c')}</button>
          </li>
          <li>
            <button onClick={() => handleOptionSelect('Set Suggestions Channel')}>{t('dash_suggest_c')}</button>
          </li>
          <li>
            <button onClick={() => handleOptionSelect('Set Event Channel')}>{t('dash_event_c')}</button>
          </li>
          <li>
            <button onClick={() => handleOptionSelect('Set Log Channel')}>{t('dash_log_c')}</button>
          </li>
          <li>
            <button onClick={() => handleOptionSelect('Set Giveaway Channel')}>{t('dash_giveaway_c')}</button>
          </li>
          {/*<li>   because needs additional input text for welcome and support role.
            <button onClick={() => handleOptionSelect('Set Ticket Channel')}>{t('dash_ticket_c')}</button>
          </li>*/}
          <li>
            <button onClick={() => handleOptionSelect('Set Server Language')}>{t('dash_server_lang')}</button>
          </li>
          {/*<li>
            <button onClick={() => handleOptionSelect('Custom Commands')}>{t('dash_custom_cmd')}</button>
          </li>
          <li>
            <button onClick={() => handleOptionSelect('Moderation Commands')}>{t('dash_moderation_cmd')}</button>
          </li>*/}
        </ul>
        {/* Back Button */}
        <hr></hr>
        <button onClick={handleBackClick}>⏴{t('dash_back')}</button>
      </div>
      
      <div className="content">
        <div className="heading3">
          <h2>{t('dash_sel_g')}:</h2>
          <p>{t('dash_guild_id')}: {selectedGuild.id}</p>
          <p>{t('dash_guild_name')}: {selectedGuild.name}</p>
          <p>{t('dash_server_last_res')}: {backendResponse}</p>
        </div>

        {selectedOption && (
          <div className="heading3">
            <h3>{selectedOption}</h3>

            {selectedOption === 'Member Count' ? (
                <>
                <input
                  type="text"
                  value={inputValue[selectedOption] || ''}
                  onChange={(e) => handleInputChange(e, selectedOption)}
                  className="custom-input"
                  pattern="[0-9]*"  // Allow only numbers
                />
                <ToggleSwitch
                  value={inputValue[selectedOption] || 'false'}
                  onChange={(e) => handleInputChange(e, selectedOption)}
                />
              </>
            ) : (
              // Use a separate state for other options
              <>
                {selectedOption === 'Set Server Language' && (
                  <select
                    value={selectedLanguage}
                    onChange={handleLanguageChange}
                    className="custom-select"
                  >
                    {languages.map(lang => (
                      <option key={lang.language} value={lang.language}>
                        {lang.displayName}
                      </option>
                    ))}
                  </select>
                )}

                {selectedOption !== 'Set Server Language' && (
                  <input
                    type="text"
                    value={inputValue[selectedOption] || ''}
                    onChange={(e) => handleInputChange(e, selectedOption)}
                    className="custom-input"
                    pattern="[0-9]*"  // Allow only numbers
                  />
                )}

                <button onClick={handleSaveClick} className="btn3">{t('dash_btn_save')}</button>
              </>
            )}

            {changesSaved && (
              <div className="changes-saved-text">{t('dash_changes_saved')}</div>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default ServerDashboard;