import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './css/bootstrap.min.css';
import './css/main.css';
import './css/count-anim.css';
import './css/now-ui-kit.css';
import './App.css';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet';
import onlinepng from './assets/online-t.png';
import offlinepng from './assets/offline-t.png';
import favicon from './assets/favicon.ico';
import Counter from "./Counter";
import CountUp from "react-countup";

const Home = () => {
    const { t } = useTranslation();
    const [isOnline, setIsOnline] = useState(false);
    const [guildCount, setGuildCount] = useState(0);
    const [channelCount, setChannelCount] = useState(0);
    const [userCount, setUserCount] = useState(0);
  
    useEffect(() => {
        fetchData();
      }, []);
      
      const fetchData = async () => {
        try {
          const response = await axios.get('https://be-bot.limro.top/api/botinfo');
      
          if (response.data && Array.isArray(response.data) && response.data.length > 0) {
            const data = response.data[0];
            // console.log(data);
            setChannelCount(data.channels_count);
            setUserCount(data.users_count);
            setGuildCount(data.server_count);
            const currentTime = new Date();
            const currentTimeInRiga = new Date(currentTime.toLocaleString('en-US', { timeZone: 'Europe/Riga' }));
            const lastPingTimeParts = data.last_ping.split(':');
            const lastPingTime = new Date(
              currentTimeInRiga.getFullYear(),
              currentTimeInRiga.getMonth(),
              currentTimeInRiga.getDate(),
              parseInt(lastPingTimeParts[0]),
              parseInt(lastPingTimeParts[1]),
              parseInt(lastPingTimeParts[2])
            ).getTime();
      
            console.log('Time since last ping (milliseconds):', currentTime - lastPingTime);
      
            if (currentTime - lastPingTime < 60 * 60 * 1000) {
              setIsOnline(true);
            } else {
              setIsOnline(false);
            }
          }
        } catch (error) {
          console.error('Error fetching data:', error);
        }
      };

        return (
            <div>
            <Helmet>
                <link rel="icon" href={favicon}></link>
                <title>{t('nav_home')} | Limro Bot</title>
                <meta name="description" content="Explore the features of Limro Bot, a Discord Bot created by Limro Studios." />

                <meta property="og:title" content="Limro Bot - Discord Bot" />
                <meta property="og:description" content="Explore the features of Limro Bot, a Discord Bot created by Limro Studios." />
                <meta property="og:image" content="https://i.imgur.com/rxYygjn.png" />
                <meta property="og:url" content="https://limrosama.eu5.org/" />
                <meta name="og:site_name" content="Limro Bot" />
                <meta property="og:type" content="website" />

                <meta name="twitter:card" content="summary_large_image" />
                <meta name="twitter:title" content="Limro Bot - Discord Bot" />
                <meta name="twitter:description" content="Explore the features of Limro Bot, a Discord Bot created by Limro Studios." />
                <meta name="twitter:image" content="https://i.imgur.com/rxYygjn.png" />

                <script type="application/ld+json">
                    {JSON.stringify({
                        "@context": "https://schema.org",
                        "@type": "SoftwareApplication",
                        "name": "LimroBot",
                        "image": "https://i.imgur.com/rxYygjn.png",
                        "description": "Explore the features of Limro Bot, a Discord Bot created by Limro Studios.",
                        "applicationCategory": "Communication",
                        "operatingSystem": "Cross-platform",
                        "author": {
                            "@type": "Organization",
                            "name": "Limro Studios"
                        },
                        "offers": [
                            {
                                "@type": "Offer",
                                "price": "0",
                                "priceCurrency": "USD",
                                "availability": "https://schema.org/InStock",
                                "description": "Free Tier"
                            },
                            {
                                "@type": "Offer",
                                "price": "3",
                                "priceCurrency": "USD",
                                "availability": "https://schema.org/InStock",
                                "description": "LimroBot Basic"
                            },
                            {
                                "@type": "Offer",
                                "price": "5",
                                "priceCurrency": "USD",
                                "availability": "https://schema.org/InStock",
                                "description": "LimroBot Silver"
                            },
                            {
                                "@type": "Offer",
                                "price": "10",
                                "priceCurrency": "USD",
                                "availability": "https://schema.org/InStock",
                                "description": "LimroBot Gold"
                            }
                        ]
                    })}
                </script>
            </Helmet>
            <div className="heading">
                <h1 className="display-5 title">{t('home_main_t_1')}</h1>
                <p className="subtitle">{t('home_main_t_2_1')}<br />{t('home_main_t_2_2')}<br />{t('home_main_t_2_3')}</p>
                <div className='btncontainer'>
                    <a className="btn btn-primary btn-lg" href="https://limro-studios.gitbook.io/limro-sama-docs/" target="_blank" rel="noopener noreferrer" role="button">{t('home_getstarted')}</a>
                    {/*<a className="btn btn-secondary btn-lg" href="https://discord.com/oauth2/authorize?client_id=1086632004273057792&permissions=8&scope=bot" target="_blank" rel="noopener noreferrer" role="button">{t('home_addtoserver')}</a>*/}
                    <a className="btn btn-secondary btn-lg" href="https://discord.com/api/oauth2/authorize?client_id=1086632004273057792&permissions=1378616863990&scope=bot" target="_blank" rel="noopener noreferrer" role="button">{t('home_addtoserver')}</a>
                </div>
            </div>
            <div className="statistics">
            <div className='container'>
                <div className="title">{t('home_currently')}</div>
            <div className={`card-title2 ${isOnline ? 'fade-in' : 'fade-in'}`}>
            <img src={isOnline ? onlinepng : offlinepng} alt={isOnline ? 'Online Icon' : 'Offline Icon'} className='offline-image' />
            {isOnline ? t('home_online') : t('home_offline')}
            </div>
            <div className="cards">
                <div className={`card statistic fade-in`} style={{ width: '18rem' }}>
                    <div className="card-body">
                        <p className="card-title" style={{ fontSize: '50px', lineHeight: '15px', letterSpacing: '-0.06em', fontWeight: 'bold' }}>
                            {/*{guildCount}*/}
                            <Counter number={guildCount}/>
                        </p>
                        <p style={{ fontWeight: 'bold', fontSize: '20px' }}>{t('home_servers')}</p>
                    </div>
                </div>
                <div className={`card statistic fade-in`} style={{ width: '18rem' }}>
                    <div className="card-body">
                        <p className="card-title" style={{ fontSize: '50px', lineHeight: '15px', letterSpacing: '-0.06em', fontWeight: 'bold' }}>
                            {/*{userCount}*/}
                            <Counter number={userCount}/>
                        </p>
                        <p style={{ fontWeight: 'bold', fontSize: '20px' }}>{t('home_users')}</p>
                    </div>
                </div>
                <div className={`card statistic fade-in`} style={{ width: '18rem' }}>
                    <div className="card-body">
                        <p className="card-title" style={{ fontSize: '50px', lineHeight: '15px', letterSpacing: '-0.06em', fontWeight: 'bold' }}>
                            {/*{channelCount}*/}
                            <Counter number={channelCount}/>
                        </p>
                        <p style={{ fontWeight: 'bold', fontSize: '20px' }}>{t('home_channels')}</p>
                    </div>
                </div>
            </div>
            </div>
            </div>
            <div className="features">
            <div className='container'>
                <div className="title">{t('home_features_2')}</div>
                <div className="subtitle">{t('home_features_3')}</div>
                <div className="cards">
                <div className="card" style={{ width: '18rem' }}>
                    <div className="card-body">
                    <h5 className="card-title">{t('home_moderation_1')}</h5>
                    <p className="card-text">{t('home_moderation_2')}</p>
                    </div>
                </div>
                <div className="card" style={{ width: '18rem' }}>
                    <div className="card-body">
                    <h5 className="card-title">{t('home_music_1')}</h5>
                    <p className="card-text">{t('home_music_2')}</p>
                    </div>
                </div>
                <div className="card" style={{ width: '18rem' }}>
                    <div className="card-body">
                    <h5 className="card-title">{t('home_casino_1')}</h5>
                    <p className="card-text">{t('home_casino_2')}</p>
                    </div>
                </div>
                </div> 
            </div>
            </div>
                <div className="card">
                <div className='container'>
                    <div className="card-body gs-card">
                    <div className="title">{t('home_support_1')}</div>
                    <div className="subtitle">{t('home_support_2')}</div>
                    <br />
                    <div className='btncontainer'>
                        <a className="btn btn-primary btn-lg" href="https://discord.gg/55WjVBMDvH" target="_blank" rel="noopener noreferrer" role="button">{t('home_getsupport')}</a>
                    </div>
                    <br />
                </div>
            </div>
            </div>

            {/* Add any other JSX content here if needed */}
            </div>
        );
};

export default Home;