import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import './css/leaderboard.css'; // Import the CSS file

const ClanLeaderboard = () => {
    const { t } = useTranslation();
    const [leaderboardData, setLeaderboardData] = useState([]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await fetch('https://be-bot.limro.top/api/leaderboardclans');
                if (response.ok) {
                    const data = await response.json();
                    const leaderboardArray = Object.entries(data)
                        .map(([place, clanData]) => ({
                            place,
                            ...clanData,
                        }));

                    setLeaderboardData(leaderboardArray);
                } else {
                    console.error('Failed to fetch data');
                }
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        fetchData();
    }, []);

    return (
        <div className="coin-leaderboard table-container">
            <h1 className="display-5 title3">{t('ld_clanheader')}</h1>
            <table className="leaderboard-table">
                <thead>
                    <tr>
                        <th>{t('place')}</th>
                        <th>{t('clanid')}</th>
                        <th>{t('clanname')}</th>
                        <th>{t('lvl')}</th>
                        <th>{t('members')}</th>
                        <th>{t('clancoins')}</th>
                    </tr>
                </thead>
                <tbody>
                    {leaderboardData.map((clan, index) => (
                        <tr key={index}>
                            <td>
                                {clan.place === '1' ? '🥇 1.' : clan.place === '2' ? '🥈 2.' : clan.place === '3' ? '🥉 3.' : `${clan.place}.`}
                            </td>
                            <td>{clan.clan_id}</td>
                            <td>{clan.name}</td>
                            <td>{clan.lvl}</td>
                            <td>{clan.member_count} / {clan.max_members}</td>
                            <td>{clan.membercoins}</td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
};

export default ClanLeaderboard;