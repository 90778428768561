import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import './css/bootstrap.min.css';
import './css/main.css';
import './css/now-ui-kit.css';
import './App.css';
import './css/dashboard.css';
import noserverpfp from './assets/noserverpfp.png';
import { useTranslation } from 'react-i18next';
import Cookies from 'js-cookie';
import defaultGuildIconUrl from './assets/noserverpfp.png';
import { Helmet } from 'react-helmet';
import axios from 'axios';

const Dashboard = ({ loggedIn, setLoggedIn, user, setUser, guilds, setGuilds, sessionValid, setSessionValid }) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  
  const { guildId } = useParams();
  const [selectedGuild, setSelectedGuild] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const sessionCookie = Cookies.get('session');
  
        if (!sessionCookie) {
          console.error('Session not found!');
          // Redirect back to the login page
          clearSession();
          window.location.href = '/';
          return;
        }
  
        // Check if the session is valid
        const response = await axios.get('https://be-bot.limro.top/api/protected/check', {
          headers: {
            Authorization: `Bearer ${sessionCookie}`,
          },
        });
  
        if (response.status === 200) {
          // Session is valid
          setLoggedIn(true);
          setSessionValid(true);
        } else {
          // Session is not valid
          console.error('Invalid session');
          clearSession();
          window.location.href = '/';
        }
      } catch (error) {
        console.error('Error fetching protected data:', error.message);
        clearSession();
        window.location.href = '/';
      }
    };
  
    if (loggedIn) {
      fetchData();
    }
  }, [loggedIn, setUser, setGuilds, setLoggedIn, sessionValid, setSessionValid, navigate]);

  if (!sessionValid) {
    clearSession();
    window.location.href = '/';
    return null;
  }

  if (!user || !guilds) {
    clearSession();
    window.location.href = '/';
    return null;
  }

  async function clearSession() {
    const sessionCookie = Cookies.get('session');
    await axios.get('https://be-bot.limro.top/auth/logout', {
      headers: {
        Authorization: `Bearer ${sessionCookie}`,
      },
    });
    Cookies.remove('session', { domain: '.limro.top' });
    setLoggedIn(false);
    setSessionValid(false);
  }

  const handleGuildSelect = (guild) => {
    setSelectedGuild(guild);
    navigate(`guild/${guild.id}`, { state: { selectedGuild: guild } });
  };

  return (
    <div className="dashboard-container">
      <Helmet>
        <title>{t('auth_dashboard')} | LimroBot</title>
      </Helmet>
      {/* Sidebar */}
      <div className="server-selection-container">
      <h1 className="display-5 title" style={{ color: 'white', textAlign: 'center', borderBottom: '1px solid white', marginBottom: '10px'}}>
        {t('dash_server_sel')}
      </h1>
      <div className="server-selection">
        <ul>
          {guilds.map((guild) => (
            <li key={guild.id}>
              <button onClick={() => handleGuildSelect(guild)}>
                <img
                  src={`https://cdn.discordapp.com/icons/${guild.id}/${guild.icon}.png`}
                  alt={guild.name}
                  className="guild-iconn"
                  onError={(e) => {
                    if (!guild.icon) {
                      e.target.src = defaultGuildIconUrl;
                    }
                  }}
                />
                {guild.name}
              </button>
            </li>
          ))}
        </ul>
      </div>
    </div>

    </div>
  );
};

export default Dashboard;