import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import './css/leaderboard.css'; // Import the CSS file

const CoinLeaderboard = () => {
    const { t } = useTranslation();
    const [leaderboardData, setLeaderboardData] = useState([]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await fetch('https://be-bot.limro.top/api/leaderboardcoins');
                if (response.ok) {
                    const data = await response.json();
                    const leaderboardArray = Object.entries(data)
                        .map(([place, userData]) => ({
                            place,
                            ...userData,
                        }));

                    setLeaderboardData(leaderboardArray);
                } else {
                    console.error('Failed to fetch data');
                }
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        fetchData();
    }, []);

    return (
        <div className="coin-leaderboard table-container">
            <h1 className="display-5 title3">{t('ld_coinheader')}</h1>
            <table className="leaderboard-table">
                <thead>
                    <tr>
                        <th>{t('place')}</th>
                        <th>{t('rank')}</th>
                        <th>{t('name')}</th>
                        <th>{t('lvl')}</th>
                        <th>{t('cash')}</th>
                    </tr>
                </thead>
                <tbody>
                {leaderboardData.map((user, index) => (
                    <tr key={index}>
                        <td>
                            {user.place === '1' ? '🥇 1.' : user.place === '2' ? '🥈 2.' : user.place === '3' ? '🥉 3.' : `${user.place}.`}
                        </td>
                        <td>{user.rank}</td>
                        <td>{user.name}</td>
                        <td>{user.lvl}</td>
                        <td>{user.cash}</td>
                    </tr>
                ))}
                </tbody>
            </table>
        </div>
    );
};

export default CoinLeaderboard;