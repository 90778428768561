import React, { useEffect, useState, useRef } from 'react';
import { Routes, Route, Link, useLocation, useNavigate } from 'react-router-dom';
import About from './About';
import Home from './Home';
import Features from './Features';
import PrivacyPolicy from './PrivacyPolicy';
import TermsOfUse from './TermsOfUse'
import NotFound from './NotFound';
import Callback from './Callback';
import BadApple from './Badappleplayedonlimrosamawebsite';
import LoginError from './LoginError';
import Dashboard from './Dashboard';
import ServerDashboard from './ServerDashboard';
import Leaderboards from './Leaderboards';
import Auditlog from './Auditlog';
import Profile from './Profile';
import GDPRPrivacyPolicy from './GDPRPolicy';
import logo from './assets/fullsizelogo.png';
import vallogo from './assets/lirmoarbantiti.png';
import menuIcon from './assets/menuIcon.svg';
import { useTranslation } from 'react-i18next';
import i18n from './i18n';
import './css/system-messages.css';
import Cookies from 'js-cookie';
import nopfp from './assets/nopfp.png';
import SystemNotification from './SystemNotification'
//import { isLoggedIn } from './SetLoginStateUtil';
import CryptoJS from 'crypto-js';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import LimroStudios from './assets/LimroStudiosLogo.png';
import './css/footer.css';
import { faTwitter, faDiscord, faItchIo, faYoutube, faPatreon } from '@fortawesome/free-brands-svg-icons';
import Subscriptions from './Subscriptions';
import CustomScrollbar from './customscrollbar.jsx';
import SimpleBar from 'simplebar-react';
import 'simplebar-react/dist/simplebar.min.css';
import axios from 'axios';
import { TransitionGroup, CSSTransition } from 'react-transition-group';
import SuccessfulPayment from './SuccessfulPayment.js';
import CanceledPayment from './CanceledPayment.js';
import ThanksForAdding from './ThanksForAdding';

//import ReactGA from 'react-ga';
//import Particle from './assets/Particles';
//r
//ReactGA.initialize('G-G9ECVYDBVC');

//emojis
import globus from './assets/globus.png'
import us from './assets/us.png'
import ru from './assets/ru.png'
import cn from './assets/cnzh.png'
import jp from './assets/jp.png'
import ko from './assets/ko.png'
import coin from './assets/coin.png'
import lvl from './assets/lvl.png'
import clan from './assets/clan.png'

const getMessageStyle = (msgPriority) => {
  switch (msgPriority) {
    case 1:
      return 'system-message-green';
    case 2:
      return 'system-message-yellow';
    case 3:
      return 'system-message-red';
    default:
      return 'system-message-gray';
  }
};

const App = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const location = useLocation();
  const [systemMessages, setSystemMessages] = useState([]);
  const [user, setUser] = useState(null);
  const [loggedIn, setLoggedIn] = useState(false);
  const [isNavCollapsed, setIsNavCollapsed] = useState(true);
  const navbarRef = useRef(null);
  const [guild, setGuilds] = useState([]);
  const [selectedOption, setSelectedOption] = useState('coin');
  const [acceptedCookies, setAcceptedCookies] = useState(false);
  const currentYear = new Date().getFullYear();
  const isDifferentYear = currentYear !== 2023;
  const isHomePage = location.pathname === '/';
  const [sessionValid, setSessionValid] = useState(true);
  const [isDarkMode, setIsDarkMode] = useState(true);
  const [themeLoaded, setThemeLoaded] = useState(false);
  const [isValentinesWeek, setIsValentinesWeek] = useState(false);

  const isValentinesDayWeek = () => {
    const currentDate = new Date();
    const startValentinesWeek = new Date(currentDate.getFullYear(), 1, 11); // February 11th
    const endValentinesWeek = new Date(currentDate.getFullYear(), 1, 18); // February 18th

    return currentDate >= startValentinesWeek && currentDate <= endValentinesWeek;
};

  const changeTheme = (isDark) => {
    //setIsDarkMode(isDark);
    //localStorage.setItem('darkMode', isDark);
    setIsDarkMode(true);
    localStorage.setItem('darkMode', true);
  };

  const handleNavToggle = () => {
    setIsNavCollapsed(!isNavCollapsed);
  };

  const handleOtherButtonClick = () => {
    setIsNavCollapsed(true);
  };

  const handleAcceptCookies = () => {
    setAcceptedCookies(true);
    Cookies.set('acceptedCookies', true, {
      expires: 365,
      httpOnly: false,
      secure: true,
      sameSite: 'strict',
    });
  };

  useEffect(() => {
    const deleteExpiredCookies = () => {
      // Get all cookies
      const allCookies = Cookies.get();

      // Iterate over each cookie
      Object.keys(allCookies).forEach((cookieName) => {
        // Get the expiration date of the cookie
        const expirationDate = new Date(Cookies.get(cookieName, { raw: true }));

        // Check if the cookie is expired
        if (expirationDate < new Date()) {
          // If expired, delete the cookie
          Cookies.remove(cookieName, { domain: '.limro.top' });
        }
      });
    };

    deleteExpiredCookies();

    const acceptedCookies = Cookies.get('acceptedCookies');

    if (acceptedCookies === undefined || acceptedCookies === 'false') {
      setAcceptedCookies(false);
    } else {
      setAcceptedCookies(true);
    }

    const handleClickOutside = (event) => {
      if (navbarRef.current && !navbarRef.current.contains(event.target)) {
        setIsNavCollapsed(true);
      }
    };

    document.addEventListener('click', handleClickOutside);

    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, []);


  useEffect(() => {
    window.scrollTo(0, 0);
    //fetchUserData();
  }, [location.pathname]);

  const handleLogout = async () => {
    try {
      const sessionCookie = Cookies.get('session');
  
      // Make a request to the backend to logout
      await axios.get('https://be-bot.limro.top/auth/logout', {
        headers: {
          Authorization: `Bearer ${sessionCookie}`,
        },
      });
  
      // Clear local state and redirect
      Cookies.remove('session', { domain: '.limro.top', path: '/' });
      sessionStorage.removeItem('backendResponse');
      setUser(null);
      setGuilds(null);
      setLoggedIn(false);
      window.location.href = '/';
    } catch (error) {
      console.error('Error during logout:', error);
    }
  };

  const handleLogin = async () => {
    try {
      // Fetch login status from the server
      const response = await fetch('https://be-bot.limro.top/status/login');
      const data = await response.json();
  
      let sessionId = '';
      // Check if login is enabled
      if (data.loginEnabled === '1') {
        //const scopes = ['identify', 'email', 'guilds', 'guilds.members.read'];
  
        //const clientId = '1086632004273057792';
        //const redirectUri = 'https://backend2--marosusamogu.repl.co/auth/discord/callback';
  
        //const authUrl = `https://discord.com/api/oauth2/authorize?client_id=${clientId}&redirect_uri=${encodeURIComponent(redirectUri)}&response_type=token&scope=${scopes.join('%20')}`;
        
        //generate session_id
        if (typeof window !== 'undefined' && window.crypto) {
          const randomBytes = new Uint8Array(16);
          window.crypto.getRandomValues(randomBytes);
          sessionId = Array.from(randomBytes, byte => byte.toString(16).padStart(2, '0')).join('');
        } else {
          const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
          const sessionIdLength = 32;
      
          for (let i = 0; i < sessionIdLength; i++) {
            const randomIndex = Math.floor(Math.random() * characters.length);
            sessionId += characters.charAt(randomIndex);
          }
        }
      
        const expirationTime = 3 * 60 * 60; // 3 hours
        //Cookies.set('session', sessionId, { expires: expirationTime, domain: '.limro.top', path: '/' });
		Cookies.set('session', sessionId, {
		  expires: expirationTime,
		  domain: '.limro.top',
		  path: '/',
		  secure: true,
		  sameSite: 'Lax'  // You can also use 'Lax' or 'None' depending on your requirements
		  //httpOnly: true
		});
        //Cookies.set('session', sessionId, { expires: expirationTime });

        const authUrl = `https://be-bot.limro.top/auth/discord`;
    
        fetch(authUrl, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${sessionId}`,
          },
        })
        .then(response => {
          console.log(response);
        })
        .catch(error => {
          console.error(error);
        });
    
        // Redirect to the authentication URL
        window.location.href = authUrl;
        
        //const authUrl = `https://be-bot.limro.top/auth/discord`;
        //window.location.href = authUrl;
      } else {
        console.log("Login is disabled by Administrator!");
      }
    } catch (error) {
      console.log("Internal Server Error!");
    }
  };

  useEffect(() => {
    setIsValentinesWeek(isValentinesDayWeek());
    const fetchData = async () => {
      try {
        const sessionCookie = Cookies.get('session');
  
        if (!sessionCookie) {
          console.error('Session not found!');
          return;
        }
  
        const response = await axios.get('https://be-bot.limro.top/api/protected', {
          headers: {
            Authorization: `Bearer ${sessionCookie}`,
          },
        });
  
        // Destructure the response data
        const { user, adminGuilds } = response.data;
  
        // Update the state with the fetched data
        setUser(user);
        setGuilds(adminGuilds); // Assuming you have a state variable named adminGuilds
        setLoggedIn(true);
      } catch (error) {
        // Handle errors, e.g., unauthorized access
        console.error('Error fetching protected data:', error.message);
  
        if (error.response && error.response.status === 429) {
          // Rate limit exceeded, implement rate limit handling here
          const retryAfter = Math.ceil(error.response.data.retry_after * 1000); // Convert to milliseconds
          await new Promise(resolve => setTimeout(resolve, retryAfter));
          fetchData(); // Retry the request
        } else {
          // Set the 'loggedIn' state to false for other errors
          setLoggedIn(false);
        }
      }
    };
  
    fetchData();
  }, []);  

  const changeLanguage = (language) => {
    i18n.changeLanguage(language);
    localStorage.setItem('lang', language);
  };

  const handleOptionChange = (event) => {
    const newSelectedOption = event.target.value;
    setSelectedOption(newSelectedOption);
    navigate(`/leaderboard/${newSelectedOption}`);
};

const navigateToCoinsLeaderboard = () => {
    setSelectedOption('coin');
    window.location.href = '/leaderboard/coins';
};

const navigateToProfile = () => {
  window.location.href = '/profile/gambling';
};

const navigateToAudit = () => {
  window.location.href = '/profile/auditlog';
};

const navigateToLVLLeaderboard = () => {
    setSelectedOption('lvl');
    window.location.href = '/leaderboard/lvl';
};

const navigateToClansLeaderboard = () => {
  setSelectedOption('clans');
  window.location.href = '/leaderboard/clans';
};

  useEffect(() => {
    const lang = localStorage.getItem('lang');
    let languageToSet;
    if (lang) {
      i18n.changeLanguage(lang);
    } else {
      const browserLanguage = navigator.language || navigator.userLanguage;
      const defaultLanguage = browserLanguage.substr(0, 2);

      if (defaultLanguage === 'zh') {
        languageToSet = 'zh-CN';
      } else if (defaultLanguage === 'ko') {
        languageToSet = 'ko-KR';
      } else if (defaultLanguage === 'jp') {
        languageToSet = 'jp-JP';
      } else {
        languageToSet = ['en', 'ru', 'zh-CN', 'jp-JP', 'ko-KR'].includes(defaultLanguage) ? defaultLanguage : 'en';
      }

      localStorage.setItem('lang', languageToSet);
    }

    const themeMode = localStorage.getItem('darkMode');

    if (themeMode === null) {
      // Check system preferences if dark mode preference is not stored
      if (window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches) {
        changeTheme(true);
      } else if (window.matchMedia && window.matchMedia('(prefers-color-scheme: light)').matches) {
        changeTheme(false);
      } else {
        //default
        changeTheme(true);
      }
    } else {
      setIsDarkMode(themeMode === 'true');
    }

    setTimeout(() => {
      setThemeLoaded(true);
    }, 500);
    
    const fetchSystemMessages = () => {
      fetch('https://be-bot.limro.top/api/system-messages')
        .then((response) => response.json())
        .then((data) => {
          // Check if the new messages are different from the existing state
          if (JSON.stringify(data) !== JSON.stringify(systemMessages)) {
            setSystemMessages(data);
          }
        })
        .catch((error) => console.error('Error fetching system messages:', error));
    };
    fetchSystemMessages();
    const fetchInterval = setInterval(fetchSystemMessages, 3000);
    return () => {
      clearInterval(fetchInterval);
    };
  }, [systemMessages, i18n]);

  //useEffect(() => {
  //  const userIsLoggedIn = isLoggedIn();
  //  setLoggedIn(userIsLoggedIn);
  //}, []);

  return (
    <div className={`theme-container ${themeLoaded ? 'loaded' : ''}`}>
    <div className={`theme-transition ${isDarkMode ? 'dark-mode' : 'light-mode'}`}>
    <div className='custom-scrollbar-wrapper'>
      <SimpleBar className='simplebar_css' style={{ paddingRight: '0px', overflowX: 'hidden' }} autoHide={false}>
        <div>
        <nav className={isDarkMode ? 'navbar navbar-expand-lg' : 'navbar-white navbar-expand-lg'} ref={navbarRef}>
        <button
          className="navbar-toggler"
          type="button"
          data-toggle="collapse"
          data-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent"
          aria-expanded={!isNavCollapsed}
          aria-label="Toggle navigation"
          onClick={handleNavToggle}
        >
          <img src={menuIcon} width="20" height="20" alt="Menu Icon" style={{ maxWidth: 'none !important' }} />
        </button>
        <Link to="/">
            <img
                src={isValentinesWeek ? vallogo : logo}
                width="110"
                height="30"
                style={{ marginRight: "20px" }}
                alt="Logo"
            />
        </Link>
          <div className="collapse navbar-collapse" id="navbarSupportedContent" style={{ marginLeft: '20px !important' }}>
            <ul className="navbar-nav mr-auto">
              <li className="nav-item active">
                <Link to="/" className="nav-link">{t('nav_home')}<span className="sr-only">(current)</span></Link>
              </li>
              <li className="nav-item">
                <CSSTransition timeout={500} classNames="fade">
                <Link to="/features" onClick={handleOtherButtonClick} className="nav-link">{t('nav_features')}<span className="sr-only">(current)</span></Link>
                </CSSTransition>
              </li>
              {/*<li className="nav-item">
                <a className="nav-link" target="_blank" href="https://www.patreon.com/limrostudios">{t('nav_donate')}</a>
              </li>*/}
              <li className="nav-item">
              <Link to="/subscriptions" onClick={handleOtherButtonClick} className="nav-link">{t('nav_subscription')}<span className="sr-only">(current)</span></Link>
              </li>
            </ul>
              <div className="nav-item dropdown">
                <a className="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                {t('home_leaderboards')}
                </a>
                <div className="dropdown-menu" aria-labelledby="navbarDropdown">
                <button className="dropdown-item" onClick={navigateToCoinsLeaderboard}>
                  <img src={coin} width="15" height="15" style={{ marginRight: '2px' }} />
                  {t('app_coins')}
                </button>
                <div className="dropdown-divider"></div>
                <button className="dropdown-item" onClick={navigateToLVLLeaderboard}>
                  <img src={lvl} width="15" height="17" style={{ marginRight: '2px' }} />
                  {t('app_lvl')}
                </button>
                {/* <div className="dropdown-divider"></div>
                <button className="dropdown-item" onClick={navigateToClansLeaderboard}>
                  <img src={clan} width="15" height="15" style={{ marginRight: '2px' }} /> 
                  {t('app_clans')}
                </button> */}
              </div>
              </div>
            <div className="nav-item dropdown">
              <a className="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                {t('nav_documentation')}
              </a>
              <div className="dropdown-menu" aria-labelledby="navbarDropdown">
                <a className="dropdown-item" target="_blank" href="https://limro-studios.gitbook.io/limro-sama-docs/limro-sama-commands/standart-commands">{t('nav_commands')}</a>
                <div className="dropdown-divider"></div>
                <a className="dropdown-item" target="_blank" href="https://limro-studios.gitbook.io/limro-sama-docs/setting-up-limro-sama/setting-up-suggestions-channel">{t('nav_tutorials')}</a>
              </div>
            </div>
            <div className="nav-item">
              <Link to="/about" className="nav-link">{t('nav_about')}</Link>
            </div>
                        <div className="nav-item dropdown">
              <a className="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                {/*{<img src={globus} width="20" height="20" style={{ marginRight: '2px' }} />*/}
                <svg fill="#ffff" width="20px" height="20px" viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg"><title>ionicons-v5-l</title><path d="M478.33,433.6l-90-218a22,22,0,0,0-40.67,0l-90,218a22,22,0,1,0,40.67,16.79L316.66,406H419.33l18.33,44.39A22,22,0,0,0,458,464a22,22,0,0,0,20.32-30.4ZM334.83,362,368,281.65,401.17,362Z"/><path d="M267.84,342.92a22,22,0,0,0-4.89-30.7c-.2-.15-15-11.13-36.49-34.73,39.65-53.68,62.11-114.75,71.27-143.49H330a22,22,0,0,0,0-44H214V70a22,22,0,0,0-44,0V90H54a22,22,0,0,0,0,44H251.25c-9.52,26.95-27.05,69.5-53.79,108.36-31.41-41.68-43.08-68.65-43.17-68.87a22,22,0,0,0-40.58,17c.58,1.38,14.55,34.23,52.86,83.93.92,1.19,1.83,2.35,2.74,3.51-39.24,44.35-77.74,71.86-93.85,80.74a22,22,0,1,0,21.07,38.63c2.16-1.18,48.6-26.89,101.63-85.59,22.52,24.08,38,35.44,38.93,36.1a22,22,0,0,0,30.75-4.9Z"/></svg>
                {/*{t('nav_language')}*/}
              </a>
              <div className="dropdown-menu" aria-labelledby="navbarDropdown">
                <button className="dropdown-item" onClick={() => changeLanguage('en')}>
                <img src={us} alt="us" width="16" height="12" style={{ marginRight: '2px' }} />
                English
                </button>
                <div className="dropdown-divider"></div>
                <button className="dropdown-item" onClick={() => changeLanguage('ru')}>
                <img src={ru} alt="ru" width="16" height="12" style={{ marginRight: '2px' }} />
                Русский
                </button>
                <div className="dropdown-divider"></div>
                <button className="dropdown-item" onClick={() => changeLanguage('cn')}>
                <img src={cn} alt="cn" width="16" height="12" style={{ marginRight: '2px' }} />
                简体中文
                </button>
                <div className="dropdown-divider"></div>
                <button className="dropdown-item" onClick={() => changeLanguage('jp')}>
                <img src={jp} alt="jp" width="16" height="12" style={{ marginRight: '2px' }} />
                日本語
                </button>
                <div className="dropdown-divider"></div>
                <button className="dropdown-item" onClick={() => changeLanguage('ko')}>
                <img src={ko} alt="ko" width="16" height="12" style={{ marginRight: '2px' }} />
                한국어
                </button>
              </div>
              <div className="dropdown-menu" aria-labelledby="navbarDropdown">
                <button className="dropdown-item" onClick={() => changeLanguage('en')}>
                <img src={us} alt="us" width="16" height="12" style={{ marginRight: '2px' }} />
                English
                </button>
              </div>
            </div>

            {/*<div className="nav-item dropdown">
              <a className="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                {isDarkMode ? '🌑' : '🌕'}
              </a>
              <div className="dropdown-menu" aria-labelledby="navbarDropdown">
                <button className="dropdown-item" onClick={() => changeTheme(false)}>
                  <img src={us} alt="us" width="16" height="12" style={{ marginRight: '2px' }} />
                  Light
                </button>
                <div className="dropdown-divider"></div>
                <button className="dropdown-item" onClick={() => changeTheme(true)}>
                  <img src={us} alt="us" width="16" height="12" style={{ marginRight: '2px' }} />
                  Dark
                </button>
              </div>
            </div>*/}

            {loggedIn && user && guild ? (
        <div className="nav-item dropdown">
          <a
            className="nav-link dropdown-toggle"
            href="#"
            id="navbarDropdown"
            role="button"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
          >
            <img
              src={`https://cdn.discordapp.com/avatars/${user.id}/${user.avatar}.png`}
              alt="User Profile"
              className="hover-img"
              style={{
                width: '30px',
                height: '30px',
                borderRadius: '30%',
                marginRight: '5px',
              }}
              onError={(e) => {
                e.target.src = nopfp;
              }}
            />
            {user.global_name ?? user.username}
          </a>
          <div className="dropdown-menu" aria-labelledby="navbarDropdown">
          {/*<button className="dropdown-item" onClick={() => navigate("/dashboard")}>
              Profile
            </button>
            <div className="dropdown-divider"></div>f*/}
            {/* <button className="dropdown-item" onClick={navigateToProfile}>
            {t('profile_profile')}
            </button>
            <div className="dropdown-divider"></div> */}
            <button className="dropdown-item" onClick={navigateToAudit}>
              {t('audit_h')}
            </button>
            <div className="dropdown-divider"></div>
            {/*<button className="dropdown-item" onClick={() => window.location.href = "/dashboard"}>*/}
            <button className="dropdown-item" onClick={() => navigate("/dashboard")}>
              {t('auth_dashboard')}
            </button>
            <div className="dropdown-divider"></div>
            <button className="dropdown-item" onClick={handleLogout}>
              {t('auth_logout')}
            </button>
          </div>
        </div>
      ) : (
        <button
          className="btn2 login-btn btn-outline-accent my-2 my-sm-0"
          onClick={handleLogin}
          style={{ fontSize: '10px !important', fontFamily: 'poppins !important' }}
        >
          <FontAwesomeIcon icon={faDiscord} style={{ fontSize: '22px', width: '20px', height: '17px' }} />  {t('auth_login')}
        </button>
      )}
          </div>

        </nav>

        {systemMessages.length > 0 && (
          <div className="system-messages-container">
            {systemMessages.map((message, index) => (
              <div
                key={index}
                className={`system-message ${getMessageStyle(message.msg_priority)}`}
              >
                {message.message}
              </div>
            ))}
          </div>
        )}
        
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/about" element={<About />} />
          <Route path="*" element={<NotFound />} />
          <Route path="/privacypolicy" element={<PrivacyPolicy />} />
          <Route path="/gdprprivacypolicy" element={<GDPRPrivacyPolicy />} />
          <Route path="/termsofuse" element={<TermsOfUse />} />
          <Route path="/features" element={<Features />} />
          <Route path="/loginerror" element={<LoginError />} />
          <Route path="/bapolsw" element={<BadApple />} />
          <Route path="/discord/auth/callback" element={<Callback />} />
          <Route path="/dashboard" element={<Dashboard loggedIn={loggedIn} setLoggedIn={setLoggedIn} user={user} setUser={setUser} guilds={guild} setGuilds={setGuilds} sessionValid={sessionValid} setSessionValid={setSessionValid} />} />
          <Route path="/dashboard/guild/:guildId" element={<ServerDashboard loggedIn={loggedIn} setLoggedIn={setLoggedIn} user={user} setUser={setUser} guilds={guild} setGuilds={setGuilds} sessionValid={sessionValid} setSessionValid={setSessionValid} />} />
          <Route path="/leaderboard/coins" element={<Leaderboards />} />
          <Route path="/leaderboard/lvl" element={<Leaderboards />} />
          <Route path="/leaderboard/clans" element={<Leaderboards />} />
          <Route path="/profile/auditlog" element={<Auditlog loggedIn={loggedIn} setLoggedIn={setLoggedIn} user={user} setUser={setUser} guilds={guild} setGuilds={setGuilds} sessionValid={sessionValid} setSessionValid={setSessionValid} />} />
          <Route path="/profile/gambling" element={<Profile loggedIn={loggedIn} setLoggedIn={setLoggedIn} user={user} setUser={setUser} guilds={guild} setGuilds={setGuilds} sessionValid={sessionValid} setSessionValid={setSessionValid} />} />
          <Route path="/profile/languages" element={<Profile loggedIn={loggedIn} setLoggedIn={setLoggedIn} user={user} setUser={setUser} guilds={guild} setGuilds={setGuilds} sessionValid={sessionValid} setSessionValid={setSessionValid} />} />
          <Route path="/subscriptions" element={<Subscriptions loggedIn={loggedIn} setLoggedIn={setLoggedIn} user={user} setUser={setUser} guilds={guild} setGuilds={setGuilds} sessionValid={sessionValid} setSessionValid={setSessionValid} handleLogin={handleLogin} />} />
          <Route path="/success" element={<SuccessfulPayment />} />
          <Route path="/cancel" element={<CanceledPayment />} />
          <Route path="/thanks" element={<ThanksForAdding />} />
        </Routes>
        
        {!acceptedCookies && (
          <div className="accept-cookies-container">
            <button className="close-cookies-button" onClick={handleAcceptCookies}>
              {/*{t('btn_close')}*/}
              X
            </button>
            <div className="accept-cookies-content">
              <p>
                {t('acceptcookies')}
              </p>
            </div>
          </div>
        )}

        <div className="footer2">
            <div className="sb_footer section_padding">
              <div className='sb_footer-links'>
                <div className='sb_footer-links-div'>
                  <h4>{t('ft_subsciptions')}</h4>
                  <a href="/subscriptions">
                    <p>{t('nav_subscription')}</p>
                  </a>
                  {/*<a href="https://www.patreon.com/limrostudios">
                    <p>{t('ft_donate')}</p>
                  </a>*/}
                </div>
                <div className='sb_footer-links-div'>
                  <h4>{t('ft_docs')}</h4>
                    <a href="https://limro-studios.gitbook.io/limro-sama-docs/limro-sama-commands/standart-commands">
                      <p>{t('ft_cmdls')}</p>
                    </a>
                    <a href="https://limro-studios.gitbook.io/limro-sama-docs/setting-up-limro-sama/setting-up-suggestions-channel">
                      <p>{t('ft_setup')}</p>
                    </a>
                </div>
                <div className='sb_footer-links-div'>
                  <h4>{t('ft_partners')}</h4>
                    <a href="/about">
                      <p>{t('ft_credits')}</p>
                    </a>
                </div>
                <div className='sb_footer-links-div'>
                  <h4>{t('ft_otherli')}</h4>
                    {/*<a href="/kkur">
                      <p>Limro Studios</p>
                    </a>*/}
                    <a href="mailto:limrostudios@limro.top">
                      <p>{t('ft_supemail')}</p>
                    </a>
                </div>
                <div className='sb_footer-links-div'>
                  <h4>{t('ft_socialmedia')}:</h4>
                    <a href="https://twitter.com/LimroStudios" target="_blank" rel="noopener noreferrer" className="social-link">
                      <FontAwesomeIcon icon={faTwitter} />
                    </a>
                    <a href="https://discord.gg/55WjVBMDvH" target="_blank" rel="noopener noreferrer" className="social-link">
                      <FontAwesomeIcon icon={faDiscord} />
                    </a>
                    <a href="https://limro-studios.itch.io/" target="_blank" rel="noopener noreferrer" className="social-link">
                      <FontAwesomeIcon icon={faItchIo} />
                    </a>
                    <a href="https://www.youtube.com/channel/UC-V5fPOQfFtIQLAGwJA-a3g" target="_blank" rel="noopener noreferrer" className="social-link">
                      <FontAwesomeIcon icon={faYoutube} />
                    </a>
                    {/*<a href="https://www.patreon.com/limrostudios" target="_blank" rel="noopener noreferrer" className="social-link">
                      <FontAwesomeIcon icon={faPatreon} />
                    </a>*/}
                </div>
              </div>
            <hr></hr>
            <div className='sb_footer-below'>
              <div className='sb_footer-copyright'>
                <p>{t('foot_copyright')} © Limro Studios | {currentYear}</p>
              </div>
              {/*<img src={LimroStudios} alt="Limro Studios" className='imge' />
              <img src={logo} alt="LimroBot" className='imge2' />*/}
              <div className='sb_footer-below-links'>
                <a href="/gdprprivacypolicy"><div><p>{t('foot_gdprprivacypolicy')}</p></div></a>
                <a href="/privacypolicy"><div><p>{t('foot_privacypolicy')}</p></div></a>
                <a href="/termsofuse"><div><p>{t('foot_termsofuse')}</p></div></a>
              </div>
            </div>
            </div>
        </div>
      </div>
    </SimpleBar>
    </div>
    </div>
    </div>
  );
};

export default App;