import { useEffect } from 'react';
import './css/bootstrap.min.css';
import './css/main.css';
import './css/now-ui-kit.css';
import './App.css';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet';
import { useNavigate, useLocation } from 'react-router-dom';

const SuccessfulPayment = () => {
    const navigate = useNavigate();
    const { t } = useTranslation();
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const session = searchParams.get('session');
    const tier = searchParams.get('tier');
    const userId = searchParams.get('userId');
    const paymentIntentId = searchParams.get('paymentIntentId');

    if (!session || !isValidUUID(session) || !tier || !userId || !paymentIntentId) {
        return navigate('/');
    }

    function getTierName(tier) {
        if(tier==='firsttier'){
            return 'Bronze Tier';
        } else if (tier==='secondtier'){
            return 'Silver Tier';
        } else if (tier==='thirdtier'){
            return 'Gold Tier';
        } else {
            return 'Unknown';
        }
    }

    function getServerCount(tier) {
        if(tier==='firsttier'){
            return 1;
        } else if (tier==='secondtier'){
            return 2;
        } else if (tier==='thirdtier'){
            return 3;
        } else {
            return 0;
        }
    }

    return (
        <div>
            <Helmet>
                <title>{t('purchase_success')} | LimroBot</title>
            </Helmet>
            
            <div className='success_container'>
                <div className='heading3'>
                    <h1 className='title'>{t('purchase_success')}!</h1>
                    <div className="success-icon">
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="green" width="200px" height="200px">
                            <path d="M0 0h24v24H0V0z" fill="none"/>
                            <path d="M9 16.2L4.8 12l-1.4 1.4L9 19 21 7l-1.4-1.4L9 16.2z"/>
                        </svg>
                    </div>
                    <p>{t('pur_success_msg1')} 1 {getTierName(tier)} {t('pur_success_msg2')} "{userId}" {t('pur_success_msg3')} {getServerCount(tier)} {getTierName(tier)} {t('pur_success_msg4')}!</p>
                </div>
            </div>
        </div>
    );

    function isValidUUID(uuid) {
        const uuidRegex = /^[0-9A-F]{8}-[0-9A-F]{4}-[4][0-9A-F]{3}-[89AB][0-9A-F]{3}-[0-9A-F]{12}$/i;
        return uuidRegex.test(uuid);
    }
};

export default SuccessfulPayment;