import React, { useState, useEffect } from 'react';
import './css/bootstrap.min.css';
import './css/main.css';
import './css/now-ui-kit.css';
import './App.css';
import './css/auditlog.css'; // Import profile.css
import './css/profile.css'
import { useNavigate, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet';
import nopfp from './assets/nopfp.png';
import Cookies from 'js-cookie';
import axios from 'axios';
import { Link } from 'react-router-dom';
import GamblingProfile from './GamblingProfile';
import LanguagesProfile from './LanguagesProfile';

const Profile = ({ loggedIn, setLoggedIn, user, setUser, guilds, setGuilds, sessionValid, setSessionValid }) => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const location = useLocation();
    const path = location.pathname;
    const [mainData, setMainData] = useState([]);
    const [levelData, setLevelData] = useState([]);
    const [badgesData, setBadgesData] = useState({ badges: null });
    const [premiumData, setPremiumData] = useState([])
    const [isProfileVisible, setIsProfileVisible] = useState(true);
    const [selectedOption, setSelectedOption] = useState(
        path.includes('/languages') ? 'languages' : 'gambling'
    );

    // Fetch main profile data
    const fetchMainProfileData = async (page) => {
        const sessionCookie = Cookies.get('session');
        try {
            const response = await fetch(`https://be-bot.limro.top/request/profile/getmaindata`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Cookie': sessionCookie,
                    Authorization: `Bearer ${sessionCookie}`,
                },
            });

            if (!response.ok) {
                throw new Error(`HTTP error! Status: ${response.status}`);
            }

            const data = await response.json();
            setMainData(data.userData);
            setLevelData(data.level || { lvl: null, exp: null, exp_needed: null });
            setBadgesData(data.badges || { badges: null });
            fetchPremiumData();
        } catch (error) {
            console.error('Error fetching profile:', error);
        }
    };

    const fetchPremiumData = async (page) => {
        const sessionCookie = Cookies.get('session');
        try {
            const response = await fetch(`https://be-bot.limro.top/request/profile/premiumstatus`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Cookie': sessionCookie,
                    Authorization: `Bearer ${sessionCookie}`,
                },
            });

            if (!response.ok) {
                throw new Error(`HTTP error! Status: ${response.status}`);
            }

            const data = await response.json();
            setPremiumData(data)
        } catch (error) {
            console.error('Error fetching profile:', error);
        }
    };

    function getTierName(tier){
        if(tier==='firsttier'){
            return 'Bronze Tier';
        } else if(tier==='secondtier'){
            return 'Silver Tier';
        } else if(tier==='thirdtier'){
            return 'Gold Tier';
        } else {
            return 'Free Tier';
        }
    }

    const handleOptionChange = (event) => {
        const newSelectedOption = event.target.value;
        setIsProfileVisible(false); // Hide leaderboard with fade-out animation
    
        // Wait for the fade-out animation to complete before changing data
        setTimeout(() => {
            setSelectedOption(newSelectedOption);
            navigate(`/profile/${newSelectedOption}`);
    
            // After changing the selected option and navigating, show the leaderboard again
            setTimeout(() => {
                setIsProfileVisible(true); // Show leaderboard after a short delay
            }, 300); // Adjust the delay time as needed
        }, 300); // Duration of the fade-out animation
    };

    // Clear session
    async function clearSession() {
        const sessionCookie = Cookies.get('session');
        await axios.get('https://be-bot.limro.top/auth/logout', {
            headers: {
                Authorization: `Bearer ${sessionCookie}`,
            },
        });
        Cookies.remove('session', { domain: '.limro.top' });
        setLoggedIn(false);
        setSessionValid(false);
    }

    // Validate session and fetch profile data when component mounts
    useEffect(() => {
        const fetchData = async () => {
            const sessionCookie = Cookies.get('session');

            if (!sessionCookie) {
                console.error('Session not found!');
                // Redirect back to the login page
                clearSession();
                window.location.href = '/';
                return;
            }

            // Check if the session is valid
            const response = await axios.get('https://be-bot.limro.top/api/protected/check', {
                headers: {
                    Authorization: `Bearer ${sessionCookie}`,
                },
            });

            if (response.status === 200) {
                // Session is valid
                setLoggedIn(true);
                setSessionValid(true);
                fetchMainProfileData(); // Fetch profile data for the initial page
            } else {
                // Session is not valid
                console.error('Invalid session');
                clearSession();
                window.location.href = '/';
            }
        };

        if (loggedIn) {
            fetchData();
        }
    }, [loggedIn, setLoggedIn, setSessionValid]);

    return (
        <div>
            <Helmet>
                <title>{t('profile_profile')} | LimroBot</title>
            </Helmet>
            <div className='auditlog_container'>
                <h1 className="display-5 title">
                {t('profile_profile')}
                </h1>
                <div className='main-profile-container'>
                <div className='profile-container'>
                <div className="profile-image-container">
                    <img
                        src={`https://cdn.discordapp.com/avatars/${mainData.id}/${mainData.avatar}.png`}
                        alt="User Profile"
                        className="profile-image"
                        onError={(e) => {
                            e.target.src = nopfp;
                        }}
                    />
                    <div className="profile-details">
                        <p>{t('profile_gbm')}: {mainData.global_name ?? 'null'}</p>
                        <p>{t('profile_name')}: {mainData.username ?? 'null'}</p>
                        <p>{t('profile_badges')}: {badgesData.badges || 'null'}</p>
                        <p>{t('profile_premium')}: {getTierName(premiumData.sub_tier)}</p>
                        <div className="dropdown-divider"></div>
                        <p>{t('profile_lvl')}: {levelData.lvl ?? 'null'}</p>
                        <p>{t('profile_exp')}: {levelData.exp ?? 'null'}/{levelData.exp_needed ?? 'null'}</p>
                        <div className="dropdown-divider"></div>
                        <p>{t('profile_id')}: {mainData.id ?? 'null'}</p>
                        <div className="dropdown-divider"></div>
                        <p className='sb_footer-below-links'><Link to="/profile/auditlog">{t('profile_your_audit')}</Link></p>
                    </div>
                </div>
                </div>
                <div className={`coin-leaderboard ${isProfileVisible ? '' : 'hidden'}`}>
                        <div className="select-container">
                                <select
                                    className="select-dropdown"
                                    id="profileSelect"
                                    value={selectedOption}
                                    onChange={handleOptionChange}
                                >
                                    <option value="gambling">{t('profile_gamep')}</option>
                                    <option value="languages">{t('profile_langp')}</option>
                                </select>
                        </div>
                        {selectedOption === 'gambling' && <GamblingProfile />}
                        {selectedOption === 'languages' && <LanguagesProfile />}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Profile;