import React, { useState, useEffect } from 'react';
import './css/bootstrap.min.css';
import './css/main.css';
import './css/now-ui-kit.css';
import './App.css';
import './css/auditlog.css';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet';
import Cookies from 'js-cookie';
import axios from 'axios';

const AuditLogs = ({ loggedIn, setLoggedIn, user, setUser, guilds, setGuilds, sessionValid, setSessionValid }) => {
	const { t } = useTranslation();
	const [auditLogs, setAuditLogs] = useState([]);
  
	const fetchAuditLogs = async (page) => {
		const sessionCookie = Cookies.get('session');
		try {
		  const response = await fetch(`https://be-bot.limro.top/request/auditlog/get?page=1`, {
			method: 'GET',
			headers: {
			  'Content-Type': 'application/json',
			  'Cookie': sessionCookie,
			  Authorization: `Bearer ${sessionCookie}`,
			},
		  });
	  
		  if (!response.ok) {
			throw new Error(`HTTP error! Status: ${response.status}`);
		  }
	  
		  const data = await response.json();
		  setAuditLogs(data.auditLogs);
		} catch (error) {
		  console.error('Error fetching audit logs:', error);
		}
	};
  
	async function clearSession() {
	  const sessionCookie = Cookies.get('session');
	  await axios.get('https://be-bot.limro.top/auth/logout', {
		headers: {
		  Authorization: `Bearer ${sessionCookie}`,
		},
	  });
	  Cookies.remove('session', { domain: '.limro.top' });
	  setLoggedIn(false);
	  setSessionValid(false);
	}
  
	// Validate session and fetch audit logs when component mounts
	useEffect(() => {
	  const fetchData = async () => {
		const sessionCookie = Cookies.get('session');
  
		if (!sessionCookie) {
		  console.error('Session not found!');
		  // Redirect back to the login page
		  clearSession();
		  window.location.href = '/';
		  return;
		}
  
		// Check if the session is valid
		const response = await axios.get('https://be-bot.limro.top/api/protected/check', {
		  headers: {
			Authorization: `Bearer ${sessionCookie}`,
		  },
		});
  
		if (response.status === 200) {
		  // Session is valid
		  setLoggedIn(true);
		  setSessionValid(true);
		  fetchAuditLogs(); // Fetch audit logs for the initial page
		} else {
		  // Session is not valid
		  console.error('Invalid session');
		  clearSession();
		  window.location.href = '/';
		}
	  };
  
	  if (loggedIn) {
		fetchData();
	  }
	}, [loggedIn, setLoggedIn, setSessionValid]);

    return (
        <div>
            <Helmet>
                <title>{t('audit_h')} | LimroBot</title>
            </Helmet>
            <div className='auditlog_container'>
                <h1 className="display-5 title" style={{ color: 'white', textAlign: 'center', borderBottom: '1px solid white', marginBottom: '10px'}}>
                    {t('audit_header')}
                </h1>
                <table>
                    <thead>
                        <tr>
                            <th>{t('audit_msg')}</th>
                            <th>{t('audit_ip')}</th>
                            <th>{t('audit_date')}</th>
                        </tr>
                    </thead>
                    <tbody>
                        {auditLogs.map((log, index) => (
                            <tr key={index}>
                                <td>{log.message}</td>
                                <td>{log.ip}</td>
                                <td>{new Date(log.date).toLocaleString()}</td>
                            </tr>
                        ))}
                    </tbody>
								</table>
            </div>
        </div>
    );
};

export default AuditLogs;