import './css/bootstrap.min.css';
import './css/main.css';
import './css/now-ui-kit.css';
import './App.css';
import React from 'react';
import YouTube from 'react-youtube';

const BadApplePlayer = () => {
  const videoId = 'UkgK8eUdpAo';

  const opts = {
    playerVars: {
      autoplay: 1,
      controls: 0,
      modestbranding: 1,
    },
  };

  return (
    <div className="bad-apple-player">
      <YouTube videoId={videoId} opts={opts} />
    </div>
  );
};

export default BadApplePlayer;