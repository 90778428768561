import React from 'react';
import { Link } from 'react-router-dom';
import './css/bootstrap.min.css';
import './css/main.css';
import './css/now-ui-kit.css';
import './App.css';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';

const PrivacyPolicy = () => {
    const { t } = useTranslation();
        return (
            <div>
            <Helmet>
                <title>{t('foot_termsofuse')} | LimroBot</title>
            </Helmet>
            <div>
            <div className='ppolicy_tos_container'>
                <h1 className="display-5 title">Terms of Use for Limro Studios</h1>
                <p className="subtitle">Version 2 08.01.2024</p>

                <h2 className="display-5 title3">1. Terms</h2>

                <p className="subtitle">By accessing this Website, accessible from https://bot.limro.top, you are agreeing to be bound by these Website Terms and Conditions of Use and agree that you are responsible for the agreement with any applicable local laws. If you disagree with any of these terms, you are prohibited from accessing this site. The materials contained in this Website are protected by copyright and trade mark law.</p>

                <h2 className="display-5 title3">2. Use License</h2>

                <p className="subtitle">Permission is granted to temporarily download one copy of the materials on Limro Studios's Website for personal, non-commercial transitory viewing only. This is the grant of a license, not a transfer of title, and under this license you may not:</p>

                <ul>
                    <li>modify or copy the materials;</li>
                    <li>use the materials for any commercial purpose or for any public display;</li>
                    <li>attempt to reverse engineer any software contained on Limro Studios's Website;</li>
                    <li>remove any copyright or other proprietary notations from the materials; or</li>
                    <li>transferring the materials to another person or "mirror" the materials on any other server.</li>
                </ul>

                <p className="subtitle">This will let Limro Studios to terminate upon violations of any of these restrictions. Upon termination, your viewing right will also be terminated and you should destroy any downloaded materials in your possession whether it is printed or electronic format. These Terms of Service has been created with the help of the <a href="https://www.termsofservicegenerator.net">Terms Of Service Generator</a>.</p>

                <h2 className="display-5 title3">3. Disclaimer</h2>

                <p className="subtitle">All the materials on Limro Studios's Website are provided "as is". Limro Studios makes no warranties, may it be expressed or implied, therefore negates all other warranties. Furthermore, Limro Studios does not make any representations concerning the accuracy or reliability of the use of the materials on its Website or otherwise relating to such materials or any sites linked to this Website.</p>

                <h2 className="display-5 title3">4. Limitations</h2>

                <p className="subtitle">Limro Studios or its suppliers will not be hold accountable for any damages that will arise with the use or inability to use the materials on Limro Studios's Website, even if Limro Studios or an authorize representative of this Website has been notified, orally or written, of the possibility of such damage. Some jurisdiction does not allow limitations on implied warranties or limitations of liability for incidental damages, these limitations may not apply to you.</p>

                <h2 className="display-5 title3">5. Revisions and Errata</h2>

                <p className="subtitle">The materials appearing on Limro Studios's Website may include technical, typographical, or photographic errors. Limro Studios will not promise that any of the materials in this Website are accurate, complete, or current. Limro Studios may change the materials contained on its Website at any time without notice. Limro Studios does not make any commitment to update the materials.</p>

                <h2 className="display-5 title3">6. Links</h2>

                <p className="subtitle">Limro Studios has not reviewed all of the sites linked to its Website and is not responsible for the contents of any such linked site. The presence of any link does not imply endorsement by Limro Studios of the site. The use of any linked website is at the user's own risk.</p>

                <h2 className="display-5 title3">7. Site Terms of Use Modifications</h2>

                <p className="subtitle">Limro Studios may revise these Terms of Use for its Website at any time without prior notice. By using this Website, you are agreeing to be bound by the current version of these Terms and Conditions of Use.</p>

                <h2 className="display-5 title3">8. Your Privacy</h2>

                <p className="subtitle">Please read our</p>
                <a href="/privacypolicy">Universal Privacy Policy here</a>
                <p className="subtitle">also for EU citizens</p>
                <a href="/gdprprivacypolicy">GDPR Privacy Policy here</a>
                <h2 className="display-5 title3">9. Governing Law</h2>

                <p className="subtitle">Any claim related to Limro Studios's Website shall be governed by the laws of lv without regards to its conflict of law provisions.</p>     
            </div>

            </div>
                <div className="card">
                    <div className='container'>
                        <div className="card-body gs-card">
                        <div className="title">{t('home_support_1')}</div>
                        <div className="subtitle">{t('home_support_2')}</div>
                        <br />
                        <div className='btncontainer'>
                            <a className="btn btn-primary btn-lg" href="https://discord.gg/55WjVBMDvH" target="_blank" rel="noopener noreferrer" role="button">{t('home_getsupport')}</a>
                        </div>
                        <br />
                    </div>
                </div>
            </div>

            {/* Add any other JSX content here if needed */}
            </div>
        );
};

export default PrivacyPolicy;